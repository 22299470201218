<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Blogs</h1>
      </div>
    </div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="w-layout-grid grid-thirds card-grid-thirds">
          <h1>SUP DUDE!</h1>
          <a href="javascript:void(0)" class="compose_share">Share</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script id="share-widget-script" type="text/javascript" src="https://app.sendible.com/widgets/share_widget_v1_0.js" data-account-url="https://app.sendible.com/"></script>
<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
export default {
  name: "ScheduleButton",
  components: {
    Navigation
  },
  data() {
    return {};
  },
  created() {},
};
</script>
